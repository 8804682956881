import React, { Component } from 'react'
import { Typography, Chip } from '@material-ui/core'

import { projectList, IProject } from '../../../variables/projects'
import MultiLine from '../../widgets/MultiLine'

import styles from './style.module.css'

interface Props {
}
interface State {
  selectedEnvelopment?: string|null
}

class ProjectListScreen extends Component<Props, State> {
  constructor( props: Props ) {
    super( props )

    this.state = {
      selectedEnvelopment: null,
    }
  }

  onClickEnvelopment = ( envelopment: string ) => {
    this.setState( { selectedEnvelopment: envelopment } )
    return true
  }

  renderProject = () => {
    const items = []
    const projects = Object.assign([], projectList).reverse() as IProject[]
    for ( const project of projects ) {
      const envelopments = this.renderEnvelopment( project.envelopment )
      const span = this.renderSpan( project )

      items.push(
        <section key={'project_' + project.name} title={project.name}>
          <Typography variant="h3">{project.name}</Typography>
          {span}
          <MultiLine component="p" paragraph={true}>
            {project.description}
          </MultiLine>
          <div className={styles.envelopments}>
            {envelopments}
          </div>
        </section>,
      )
    }
    return items
  }

  renderSpan = ( project: IProject ) => {
    return (
      <Chip
        label={
          [
            <span key={1}>参画期間:&nbsp;</span>,
            <span key={2}>{project.start_at}</span>,
            <span key={3}>&nbsp;&rarr;&nbsp;</span>,
            <span key={4}>{project.end_at}</span>,
          ]
        }
        color="secondary"
        className={styles.span}
      />
    )
  }

  renderEnvelopment = ( envelopments: string[] ) => {
    const items = []
    for ( const envelopment of envelopments ) {
      items.push(
        <Chip
          key={'envelopment_' + envelopment}
          label={envelopment}
          className={styles.envelopment}
          variant="outlined"
          onClick={() => this.onClickEnvelopment(envelopment) }
        />,
      )
    }
    return items
  }

  render() {
    return (
      <section>
        <Typography variant="h2">Project</Typography>
        <Typography component="p" paragraph={true}>
          自分が参画したプロジェクトの一覧です。
        </Typography>
          {this.renderProject()}
      </section>
    )
  }
}

export default ProjectListScreen
