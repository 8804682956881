import React, { Component } from 'react'
import { Paper, Typography } from '@material-ui/core'

import ScrollToTopOnMount from '../../../utils/scrollToTopOnMount'

import styles from './style.module.css'

import Birth from './birth'
import Sns from './sns'
import Site from './site'

class ProfileScreen extends Component {
  render() {
    return [
      <ScrollToTopOnMount key="scrollToTop" />,
      <Paper key="article" component="article">
        <Typography variant="h1">Profile</Typography>
        <Typography variant="body2" paragraph={true}>
          はじめまして。<br/>
          羽賀子門について自己紹介させていただきます。
        </Typography>
        <Birth />
        <Sns />
        <Site />
      </Paper>,
    ]
  }
}

export default ProfileScreen
