import React, { Component } from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'

const debug = process.env.NODE_ENV !== 'production'
ReactGA.initialize( process.env.REACT_APP_GA_TRACKING_ID!, { debug } )

class GAListener extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  componentDidMount() {
    this.sendPageView( this.context.router.history.location )
    this.context.router.history.listen( this.sendPageView )
  }

  sendPageView( location: any ) {
    ReactGA.set( { page: location.pathname } )
    ReactGA.pageview( location.pathname )
  }

  render() {
    return this.props.children
  }
}

export default GAListener
