import React, { Component } from 'react'
import { Typography } from '@material-ui/core'

import howOlder from '../../../utils/howOlder'

import styles from './style.module.css'

class BirthScreen extends Component {

  render() {
    return (
      <section>
        <Typography variant="h2">Birth</Typography>
        <Typography component="p" paragraph={true}>
          東京生まれ、北海道育ち。成人後また東京へ。<br />
          物心ついた時から就職まで北海道にいたので、東京のことはそこまで詳しくないです。<br />
          <br />
          昭和59年6月生まれ ({howOlder( 19840611 )}歳)。<br />
        </Typography>
      </section>
    )
  }
}

export default BirthScreen
