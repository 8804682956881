import React, { Component } from 'react'
import { Link, Typography, Grid, Card, CardHeader, CardActions, CardContent, CardMedia, Button, IconButton } from '@material-ui/core'
import { OpenInNew } from '@material-ui/icons'

import { siteList } from '../../../variables/links'
import MultiLine from '../../widgets/MultiLine'

import styles from './style.module.css'

class SiteScreen extends Component {

  renderSite = () => {
    const items = []
    for ( const site of siteList ) {
      items.push(
        <Grid
          item
          key={'site_' + site.name}
          lg={4}
          md={6}
          sm={6}
          xs={12}
          className={styles.cardGrid}
        >
          <Card color="primary">
            <CardHeader
              title={site.link.text}
              titleTypographyProps={{component: 'h3'}}
              action={
                <Link
                  href={site.link.url}
                  target={site.link.target}
                  className={styles.link}
                  title="サイトを見る"
                >
                  <IconButton>
                    <OpenInNew />
                  </IconButton>
                </Link>
              }
            />
            <CardMedia
              component="img"
              src={site.image!}
              title={site.link.text}
              className={styles.cardImage}
            />
            <CardContent>
              <MultiLine>{site.description}</MultiLine>
            </CardContent>
            <CardActions>
              <Link
                href={site.link.url}
                target={site.link.target}
                className={styles.link}
              >
                <Button size="large" color="primary">
                  サイトを見る
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>,
      )
    }
    return items
  }

  render() {
    return (
      <section>
        <Typography variant="h2">WebSite</Typography>
        <Typography component="p" paragraph={true}>
          自分が作成したり管理しているウェブサイトです。<br />
          このサイトも含め、自分の技術確認や向上のために作ってます。
        </Typography>
        <Grid
          container
          justify="center"
          className={styles.container}
          spacing={16}
        >
          {this.renderSite()}
        </Grid>
      </section>
    )
  }
}

export default SiteScreen
