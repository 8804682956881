import React, { Component } from 'react'
import { Typography } from '@material-ui/core'
import { GoogleMap, withGoogleMap, withScriptjs, Polygon } from 'react-google-maps'

import geojson from '../../../assets/data/sapporo.json'

import styles from './style.module.css'

const GoogleMapContainer = withScriptjs(
  withGoogleMap((props: any) => (
    <GoogleMap {...props} ref={props.handleMapMounted} />
  )),
)

interface Props {
  googleMapsApiKey: string
  defaultZoom: number
  size?: {
    width: any,
    height: any,
  }
  center?: {
    lat: number,
    lng: number,
  }
}

// TODO: 汎用化する
class MapsGeojsonScreen extends Component<Props> {
  map: any
  googleMapsApiKey: any

  constructor(props: any) {
    super(props)
  }

  handleMapMounted = (map: any) => {
    this.map = map
    return this.map
  }

  renderPolygons = () => {
    const polygons = []
    for ( const feature of geojson.features ) {
      const coordinates = feature.geometry.coordinates[0]

      // 国土地理院の lat lng は google api の並びと逆
      const revCoordinates = coordinates.map((coordinate) => ({ lat: coordinate[1], lng: coordinate[0] }) )
      polygons.push(
        <Polygon
          path={revCoordinates}
          visible={true}
          key={feature.properties.N03_003 + feature.properties.N03_004}
        />,
      )
    }

    return polygons
  }

  render() {
    const { googleMapsApiKey, defaultZoom } = this.props

    if ( !googleMapsApiKey ) {
      return (<div>loading...</div>)
    }

    // TODO: zoom を画面幅で増減させる

    return (
      <section>
        <Typography variant="h2">Geo JSON</Typography>
        <Typography component="p" paragraph={true}>
          国土数値情報でダウンロードできる行政区画のデータを用いて、札幌で「区」を表示してみる。
        </Typography>

        <GoogleMapContainer
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}`}
          zoom={defaultZoom}
          handleMapMounted={this.handleMapMounted}
          containerElement={<div className={styles.mapContainer} />}
          mapElement={<div className={styles.map} />}
          loadingElement={<div className={styles.mapLoading} />}
          center={{ lat: 43.012969, lng: 141.247771 }}
        >
          {this.renderPolygons()}
        </GoogleMapContainer>
      </section>
    )
  }
}

export default MapsGeojsonScreen
