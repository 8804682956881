import React, { Component } from 'react'
import { Paper } from '@material-ui/core'

import ScrollToTopOnMount from '../../../utils/scrollToTopOnMount'

import Catch from './catch'
import Menu from './menu'
import Ability from './ability'
import Business from './business'

class HomeScreen extends Component {
  render() {
    return [
      <ScrollToTopOnMount key="scrollToTop" />,
      <Paper key="article" component="article">
        <Catch />
        <Ability />
        <Menu />
      </Paper>,
    ]
  }
}

export default HomeScreen
