import React, { Component } from 'react'
import { Paper, Typography } from '@material-ui/core'

import ScrollToTopOnMount from '../../../utils/scrollToTopOnMount'

import styles from './style.module.css'

class NotFoundScreen extends Component {
  render() {
    return [
      <ScrollToTopOnMount key="scrollToTop" />,
      <Paper key="article" component="article">
        <Typography variant="h1">NotFound</Typography>
        <Typography variant="body2" paragraph={true}>
          お探しのページは見つかりませんでした。<br/>
          左上のメニュより目的のページに移動してください。
        </Typography>
      </Paper>,
    ]
  }
}

export default NotFoundScreen
