import React, { Component } from 'react'
import { Typography } from '@material-ui/core'

import styles from './style.module.css'
import logo from '../../../assets/images/logo_shimoning.png'

class CatchScreen extends Component {

  render() {
    return (
      <section className={styles.header}>
        <img src={logo} className={styles.logo} alt="Shimoning" />
        <Typography variant="h1" className={styles.h1}>
          Shimoning
        </Typography>
        <Typography variant="body2" paragraph={true}>
          システムを作るにはチームが必要。<br/>
          チームを作るのにはメンバーが必要。<br/>
          メンバー探しに疲れたみなさまへ。<br/>
          ユーティリティープレイヤーである<br/>
          私、羽賀子門をお使いください。<br/>
        </Typography>
      </section>
    )
  }
}

export default CatchScreen
