export interface ISkill {
  name: string
  description: string[]
  subset?: any[]
  start_at: string
  end_at?: string
  self_confident: SELF_CONFIDEN
}

export interface ISkillGroup {
  name: string
  description: string[]
  children: ISkill[]
}

export type SELF_CONFIDEN = 1 | 2 | 3 | 4 | 5

export const skillList: ISkillGroup[] = [
  {
    name: 'Language',
    description: [
      '開発言語。',
      '日本語はネイティブレベル。',
      '英語はちょっと読み書きできる程度。',
    ],
    children: [
      {
        name: 'PHP',
        description: [
          '何か本格的にプログラミングしてみようと思い立って始めた。',
          '独学で色々調べながら頑張った。',
          '当時はまだ検索しても情報が全然出てこなかったので、手垢がつくほど本を読みこんだ。',
          '始めた頃はまだ4だったバージョンもいつの間にか7になり、遠いところまできたものだと思う今日この頃。',
        ],
        subset: [
          'PHP 4',
          'PHP 5',
          'PHP 7',
        ],
        start_at: '2008-01',
        self_confident: 5,
      },
      {
        name: 'JavaScript',
        description: [
          '一番最初に触ったプログラミング言語ではなかろうか。',
          'フリーのソースをコピペしてきたところから始まった。',
          '慣れてくると、ブラウザ上で複雑な計算をするフォームを作ったりしていた。',
          '今では Lambda のために node を書いたり、ネイティブアプリの開発のために React などでよく使っている。',
          '最近は jQuery を捨てて Vue.js に手を出し始めている。',
        ],
        subset: [
          'jQuery',
          'TypeScript',
          'Node.js',
          'React',
          'ReactNative',
          'Vue.js',
        ],
        start_at: '2001-01',
        self_confident: 4,
      },
      {
        name: 'Perl',
        description: [
          'インターネット黎明期に CGI でフリーソースの掲示板やチャットを改造してた程度。',
        ],
        start_at: '2001-07',
        self_confident: 2,
      },
      {
        name: 'C#.net',
        description: [
          '某ネットスーパーシステムの開発言語。',
        ],
        start_at: '2015-12',
        self_confident: 2,
      },
      {
        name: 'Objective-C',
        description: [
          '某ソーシャルゲームのネイティブ化。',
          'Apple決済も実装した。',
        ],
        start_at: '2014-01',
        end_at: '2015-04',
        self_confident: 2,
      },
      {
        name: 'Java',
        description: [
          'ほぼ Android のための Java しか書いていない。',
          '',
          '某ソーシャルゲームのネイティブ化、自社開発アプリの改修、など。',
          '現在では ReactNative だけで対応できない部分とかをちょいちょいいじっている。',
        ],
        start_at: '2014-01',
        self_confident: 2,
      },
    ],
  },
  {
    name: 'Cloud',
    description: [
      'クラウドサービス',
    ],
    children: [
      {
        name: 'AWS',
        description: [
          'もともと自分のサイトを作るのにEC2を利用したのがきっかけ。',
          'その後、某チャットシステムのAPI部分をLambdaで作ったのをきっかけに、色々なプロジェクトで利用。',
          '最近ではアプリのバックエンドとしてがっつり設計から構築までやった。',
        ],
        subset: [
          'EC2',
          'S3',
          'Lambda',
          'DynamoDB',
          'Route53',
          'SNS',
          'IAM',
          'API',
          'CloudFront',
          'WAF',
        ],
        start_at: '2014-04',
        self_confident: 3,
      },
      {
        name: 'GCP',
        description: [
          '某チャットシステムのDBとしてFireBaseを使ったのが最初。',
          'アプリのプッシュ通知配信に使用したり、ウェブサイトやアプリの解析にも使っている。',
        ],
        start_at: '2016-12',
        self_confident: 2,
      },
      {
        name: 'Sakura',
        description: [
          '某美容系プロジェクトで使用中。',
          '自分で設計したわけではないが、それを利用したり追加したりしながら使用した。',
          'サイト間VPN, リージョン間通信などなど',
        ],
        start_at: '2017-12',
        self_confident: 2,
      },
      {
        name: 'ConoHa',
        description: [
          'とあるハンズオンイベントで利用したのをきっかけに使ってみた。',
          'テンプレートを利用したサーバの立ち上げが楽だった。',
          '現在残してあるのは Kusanagi で作った WordPress',
        ],
        start_at: '2015-12',
        self_confident: 4,
      },
    ],
  },
  {
    name: 'DB',
    description: [
      'リレーショナルデータベースが中心。',
      'DynamoDBやFireBaseのようなNoSQLも使っている。',
    ],
    children: [
      {
        name: 'MySQL',
        description: [
          'PHPを初めて以来ずっと利用している。',
          '生クエリでのjoinとかをゴリゴリ書いてた。',
          '最近はフレームワークが吸収してくれるので、生クエリを書く機会は減ってしまった。',
          'チューニングも頑張ってます。',
          '',
          'phpMyAdmin を捨てろ。',
        ],
        start_at: '2010-00',
        self_confident: 4,
      },
      {
        name: 'PostgreSQL',
        description: [
          '某カーナビシステムの API を作成するときに使用。',
          'それ以降、単発の改修開発などで時々使うが、時々すぎてよく戸惑う。',
        ],
        start_at: '2014-03',
        self_confident: 3,
      },
      {
        name: 'SQLServer',
        description: [
          '某ネットスーパーシステムのDBであるため、結構使う。',
          'SSMSがなかなか優秀で助かってます。',
        ],
        start_at: '2015-12',
        self_confident: 2,
      },
    ],
  },
  {
    name: 'WebServer',
    description: [
      'ミドルウェアのインストールから、各種設定まで一通り経験している。',
    ],
    children: [
      {
        name: 'Apache',
        description: [
          'PHPを初めて以来ずっとお世話になってる。',
          '最近はNginxとかを使うことも多いけど、とりあえずPHP動かしたかったらこれ使う。',
          'インストールや設定は何十件もしたし、SSL化も何度も経験。',
        ],
        start_at: '2000-00',
        self_confident: 4,
      },
      {
        name: 'Nginx',
        description: [
          '設定ファイルが読みやすくていいですよね。',
        ],
        start_at: '2014-01',
        self_confident: 3,
      },
      {
        name: 'express',
        description: [
          'Node.js を使うならだいたいこれ。',
          '直近では GraphQL のサーバを作るのに使った。',
        ],
        start_at: '2017-02',
        self_confident: 2,
      },
      {
        name: 'IIS',
        description: [
          '某ネットスーパーシステムで採用されていたWebサーバ。',
          '最初は構築済みのものの上にプログラムを置くだけの簡単なお仕事をしていた。',
          'ある日か物理サーバ購入から各種設定を含めデプロイまで全てやることに。',
          'しんだ。',
        ],
        start_at: '2015-12',
        self_confident: 4,
      },
    ],
  },
  {
    name: 'OS',
    description: [
      'サーバとして構築したり、アプリケーション開発として利用したり。',
    ],
    children: [
      {
        name: 'CentOS 6',
        description: [
          'IT系企業に就職して初めて触ったLinux',
          'それ以降ずっと保守で利用している。',
          '物理サーバにDVDやUSBを突っ込んでインストールして、各種設定も何度となく経験。',
          'VirtualBox 上でだったら100回くらいはLAMP環境を構築してると思う。',
        ],
        start_at: '2012-12',
        self_confident: 4,
      },
      {
        name: 'CentOS 7',
        description: [
          '2017年くらいからちょいちょい触ってる。',
          '一部プロジェクトでインストールから運用までやっている。',
          'OSの起動が早くなったのでVMで開発環境作る時はとても助かってる。',
        ],
        start_at: '2017-11',
        self_confident: 3,
      },
      {
        name: 'Ubuntu',
        description: [
          'GUIを使用したいケースで何度か使った。',
          '直近ではWindowsへのRDPを簡単にできるようにする開発をした。',
        ],
        start_at: '2017-09',
        self_confident: 2,
      },
      {
        name: 'WindowsServer',
        description: [
          '某ネットスーパーシステムで採用されていた。',
          'GUIがメインだが、コマンドを叩くこともしばしばあり、手順書を作るのがなかなか大変だった。',
        ],
        subset: [
          'WindowsServer 2003R2',
          'WindowsServer 2008',
          'WindowsServer 2008R2',
        ],
        start_at: '1999-02',
        self_confident: 4,
      },
      {
        name: 'macOS',
        description: [
          'カラフルなマックが売られていた頃からちょいちょい触っていた。',
          '本格的に触り始めたのは、某ソーシャルゲームのネイティブアプリ化のため。',
          'その後しばらく触っていなかったが、格好つけのために2016年にノートを購入。',
          'それ以降持ち歩くようになり、今ではアプリ開発などでゴリゴリ使っている。',
        ],
        subset: [
          'OS X 10.8',
          'OS X 10.9',
          'OS X 10.10',
          'OS X 10.11',
          'macOS 10.12',
          'macOS 10.13',
        ],
        start_at: '2013-12',
        self_confident: 4,
      },
      {
        name: 'Windows',
        description: [
          'Windows3.1 の時に親が持っていて、ゲームとかテキストで遊んでたのが最初(流石に経験としてはノーカン)。',
          '中学生時代に授業でも触ったけど、何したか覚えてない(母の日父の日的なやつだったように思う)。',
          'インターネットが自宅にやってきてからは、毎日かじりつくように使用していた。',
          '月に2,3回はウィルスに感染し、OS入れ直しをしまくってたので、無駄に知識がついた。',
          '98SE ちゃんはいい子だよ。',
        ],
        subset: [
          'Windows 3.1',
          'Windows 98SE',
          'Windows XP',
          'Windows 7',
          'Windows 8.1',
          'Windows 10',
        ],
        start_at: '1999-02',
        self_confident: 4,
      },
    ],
  },
]
