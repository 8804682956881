import React, { Component } from 'react'
import { Typography } from '@material-ui/core'

import styles from './style.module.css'

interface Props {
  component?: any
  paragraph?: boolean
  className?: any
  children: string | string[]
}

class MultiLine extends Component<Props> {
  texts: string[] = []

  constructor( props: Props ) {
    super( props )
  }

  componentWillMount() {
    const { children } = this.props

    if ( typeof( children ) === 'string' ) {
      this.texts = children.split( '\n' )
    } else if ( Array.isArray( children ) && typeof( children[0] ) === 'string' ) {
      this.texts = children as string[]
    } else {
      this.texts = []
    }
  }

  renderTexts = () => {
    if ( this.texts.length > 0 ) {
      return this.texts.map( ( text, i ) => <span key={'text_' + i}>{text}<br /></span> )
    } else {
      return ''
    }
  }

  render() {
    const { component, paragraph, className } = this.props
    return (
      <Typography
        component={component ? component : 'div'}
        paragraph={!!paragraph}
        className={className}
      >
        {this.renderTexts()}
      </Typography>
    )
  }
}

export default MultiLine
