import React, { Component } from 'react'
import { Link, Typography, List, ListItem, ListItemText, Avatar } from '@material-ui/core'

import { snsList, ILinkIcon } from '../../../variables/links'

import styles from './style.module.css'

class SnsScreen extends Component {

  renderIcon = ( icon?: string | ILinkIcon ) => {
    if ( !icon ) {
      return null
    } else if ( typeof( icon ) === 'string' ) {
      return React.createElement( icon )
    } else {
      const { component, name } = icon
      return React.createElement( component, { name } )
    }
  }

  renderImage = ( image: any ) => {
    if ( !image ) {
      return null
    }
    return React.createElement( 'img', { src: image, className: styles.image } )
  }

  renderSns = () => {
    const items = []
    for ( const sns of snsList ) {
      items.push(
        <ListItem
          key={'sns_' + sns.name}
          color="secondary"
          className={styles.listItem}
        >
          <Avatar className={styles.listImage}>
            {this.renderIcon( sns.icon )}
            {this.renderImage( sns.image )}
          </Avatar>
          <Link
            href={sns.link.url}
            target={sns.link.target}
            className={styles.listLink}
          >
            <ListItemText
              primary={sns.name}
              secondary={sns.link.text}
              primaryTypographyProps={{ component: 'h3' }}
              className={styles.listItemText}
            />
          </Link>
        </ListItem>,
      )
    }
    return items
  }

  render() {
    return (
      <section>
        <Typography variant="h2">SNS</Typography>
        <Typography component="p" paragraph={true}>
          現在利用している SNS サイトなどです。<br />
          Follow me!
        </Typography>
        <List className={styles.list}>
          {this.renderSns()}
        </List>
      </section>
    )
  }
}

export default SnsScreen
