import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#666',
      main: '#303030',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#f0f0f0',
      dark: '#999',
      contrastText: '#666',
    },
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: 'none',
      },
    },
    MuiPaper: {
      root: {
        padding: '0.75rem',
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '3.5rem',
      },
      h2: {
        fontSize: '2.75rem',
      },
      h3: {
        fontSize: '2rem',
        borderLeft: '0.3rem solid #c0c0c0',
        paddingLeft: '0.5rem',
        marginLeft: '0.5rem',
      },
      h4: {
        fontSize: '1.7rem',
      },
      paragraph: {
        padding: '0.5rem',
      },
    },
  },
})
export default theme
