import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import MenuStore from '../../stores/menuStore'

import MapsGeojsonScreen from '../../stories/pages/Applications/maps_geojson'

interface Props {
  history?: any
  location?: any
  match?: any
  menuStore?: MenuStore
}

interface State {
  googleMapsApiKey: string
}

@inject( 'menuStore' )
@observer
class MapsGeojsonContainer extends Component<Props, State> {
  constructor( props: Props ) {
    super( props )

    this.state = {
      googleMapsApiKey: '',
    }
  }

  componentDidMount() {
    const { menuStore, location } = this.props
    menuStore!.select( location.pathname )

    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
    this.setState({ googleMapsApiKey })
  }

  render() {
    return (
      <MapsGeojsonScreen
        googleMapsApiKey={this.state.googleMapsApiKey}
        defaultZoom={9}
      />
    )
  }
}

export default MapsGeojsonContainer
