export interface IProject {
  name: string
  description: string[]
  start_at: string
  end_at?: string
  role: ROLE[]
  phase: PHASE[]
  envelopment: string[]
  site: string
}

export type ROLE = 'Programmer' | 'Director' | 'Leader' | 'Manager'

export type PHASE = 'Design' | 'Coding' | 'Testing' | 'Operation' | 'Maintenance'

export const projectList: IProject[] = [
  {
    name: '美容系業務システム',
    description: [
      '予約・顧客管理などを行える業務支援ウェブシステム。',
    ],
    start_at: '2012-12',
    end_at: '2013-01',
    role: [
      'Programmer',
    ],
    phase: [
      'Coding',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'CakePHP2',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
    ],
    site: 'GOSPA',
  },
  {
    name: 'ソーシャルゲーム',
    description: [
      '独自展開しているソーシャルゲームの保守開発。',
      '某ゲームプラットフォームへの展開対応。',
      'ゲーム内アイテム販売システム、ガチャなどの改修、着せ替え機能の改修など。',
    ],
    start_at: '2013-02',
    end_at: '2014-02',
    role: [
      'Programmer',
    ],
    phase: [
      'Coding',
      'Testing',
      'Maintenance',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
    ],
    site: 'AprilDD',
  },
  {
    name: 'SNSサービス',
    description: [
      'PC及びスマートフォン向けSNSの作成。',
      'ペットをテーマとしたもので、アバターなどを用意。',
      '洋服などを着せ替える機能なども作成。',
    ],
    start_at: '2013-02',
    end_at: '2014-02',
    role: [
      'Programmer',
    ],
    phase: [
      'Coding',
      'Testing',
      'Maintenance',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
    ],
    site: 'AprilDD',
  },
  {
    name: '広報用WordPress改修',
    description: [
      'ゲームサイトの広報のためのブログ改修',
    ],
    start_at: '2013-08',
    end_at: '2014-02',
    role: [
      'Programmer',
    ],
    phase: [
      'Coding',
      'Testing',
      'Maintenance',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
      'WordPress',
    ],
    site: 'AprilDD',
  },
  {
    name: '電子問診票',
    description: [
      'iOS 向けの電子問診票の作成。',
      'スタンドアロンで稼働し、データは csv として保存できるようにした。',
    ],
    start_at: '2013-12',
    end_at: '2014-04',
    role: [
      'Programmer',
    ],
    phase: [
      'Design',
      'Coding',
      'Testing',
    ],
    envelopment: [
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
      'Objective-C',
    ],
    site: 'AprilDD',
  },
  {
    name: 'ソーシャルゲームのネイティブアプリ化',
    description: [
      'ウェブベースのソーシャルゲームをネイティブアプリとして、スマホでプレイできるように改修。',
      'AppleやGoogleへの決済のつなぎこみ、課金データ復元機能の追加開発。',
    ],
    start_at: '2014-01',
    end_at: '2014-02',
    role: [
      'Programmer',
    ],
    phase: [
      'Design',
      'Coding',
      'Testing',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
      'Java',
      'Objective-C',
    ],
    site: 'AprilDD',
  },

  {
    name: 'ウェブナビゲーションシステム',
    description: [
      'カーナビのウェブ版作成。',
      '各種APIと連携し、地図上にデータを表示するためのつなぎこみ。',
    ],
    start_at: '2014-03',
    end_at: '2014-04',
    role: [
      'Programmer',
    ],
    phase: [
      'Coding',
      'Testing',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'PostgreSQL',
      'PHP',
      'ZendFramework2',
      'Html',
      'CSS',
      'JavaScript',
      'Angular',
    ],
    site: 'Avelio',
  },
  {
    name: 'ファッション情報サイトリニューアル',
    description: [
      'すでに稼働中のファッションサイトに機能追加。',
      '検索機能やファッション記事の投稿管理機能など。',
      '内部的には新システムとして1から作成した。',
      'テスト駆動開発を採用し、テストの自動化などを行い精度をあげていた。',
    ],
    start_at: '2014-04',
    end_at: '2015-01',
    role: [
      'Programmer',
      'Leader',
    ],
    phase: [
      'Coding',
      'Testing',
      'Maintenance',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'CakePHP2',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
    ],
    site: 'Avelio',
  },
  {
    name: '空港内業務システムのウェブリプレース',
    description: [
      '空港運営の業務システムのウェブへのリプレース。',
      'SEとしてお客様と要件定義や設計を担当。',
      '基礎部分の開発を行い、他メンバーへのタスク割り振りなど。',
    ],
    start_at: '2014-08',
    end_at: '2015-01',
    role: [
      'Leader',
      'Programmer',
    ],
    phase: [
      'Design',
      'Coding',
      'Testing',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'CakePHP2',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
    ],
    site: 'Avelio',
  },
  {
    name: '空港内情報共有ウェブシステム改修',
    description: [
      '空港内の情報を共有するシステムの改修。',
    ],
    start_at: '2014-08',
    end_at: '2015-02',
    role: [
      'Leader',
      'Programmer',
    ],
    phase: [
      'Design',
      'Coding',
      'Testing',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'CodeIgniter',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
    ],
    site: 'Avelio',
  },
  {
    name: '独自シングルサインオン開発',
    description: [
      '複数のポータルサイトを一つのIDでログインできるように、全体のシステムを改修。',
    ],
    start_at: '2015-01',
    end_at: '2015-02',
    role: [
      'Programmer',
    ],
    phase: [
      'Coding',
      'Testing',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'CakePHP2',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
    ],
    site: 'Avelio',
  },
  {
    name: '情報提供システム学習システム',
    description: [
      '空港内で利用する情報提供システム、及び学習システムの開発。',
    ],
    start_at: '2015-01',
    end_at: '2015-03',
    role: [
      'Leader',
      'Programmer',
    ],
    phase: [
      'Design',
      'Coding',
      'Testing',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'CakePHP2',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
    ],
    site: 'Avelio',
  },

  {
    name: 'ストーリー系スマホゲーム',
    description: [
      '某人気漫画(ドラマ)の新規タイトルの開発。',
      'ガチャやイベントの実装や運営',
      '管理画面の開発',
    ],
    start_at: '2015-04',
    end_at: '2015-10',
    role: [
      'Programmer',
    ],
    phase: [
      'Design',
      'Coding',
      'Testing',
      'Maintenance',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'CakePHP1.3',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
    ],
    site: 'Voltage',
  },

  {
    name: '保守',
    description: [
      '自社の様々な保守。',
      'インフラからフロントエンドまで全て一人で担当。',
      '細かすぎて覚えてないくらい色々やった。',
    ],
    start_at: '2015-11',
    end_at: '2019-04',
    role: [
      'Manager',
      'Leader',
      'Programmer',
    ],
    phase: [
      'Design',
      'Coding',
      'Testing',
      'Operation',
      'Maintenance',
    ],
    envelopment: [
    ],
    site: 'GOSPA',
  },
  {
    name: '地域ニュースサイト',
    description: [
      'ユーザー参加型のニュースサイト。',
      '登録ユーザに地域の情報を投稿してもらい、運営者が記事をユーザから買い上げて掲載する。',
      'サイトは広告を掲載することでマネタイズするモデル。',
      '設計から開発保守運営まで全て一人で担当。',
    ],
    start_at: '2015-11',
    end_at: '2016-10',
    role: [
      'Leader',
      'Programmer',
    ],
    phase: [
      'Design',
      'Coding',
      'Testing',
      'Operation',
      'Maintenance',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'CakePHP2',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
    ],
    site: 'GOSPA',
  },
  {
    name: '仕事情報マッチングサイト',
    description: [
      '仕事情報マッチングサイトの新規作成。',
      '求職者向けサイトと、管理者画面の両面作成。',
      'フレームワーク等の利用禁止という縛りがあったため、生のPHPとMySQLで作成。',
      'しかし納期のタイトさに勝てず、共通部分は独自フレームワークを作って効率化した。',
    ],
    start_at: '2017-01',
    end_at: '2017-09',
    role: [
      'Leader',
      'Programmer',
    ],
    phase: [
      'Design',
      'Coding',
      'Testing',
      'Maintenance',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
    ],
    site: 'WAN55',
  },
  {
    name: 'プラグイン型チャットシステム改修',
    description: [
      'ウェブサイトへ組み込みが可能なチャットシステムの改修。',
      'Lineやメールなども送受信し、運営者がチャット形式でカスタマーとコミュニケーションが取れるように。',
      'スマホ対応のためのデザインの調整。',
      'DBとしてFirebaseのNoSQLとAPIとしてAWSのLambdaを採用。',
    ],
    start_at: '2017-04',
    end_at: '2017-09',
    role: [
      'Programmer',
    ],
    phase: [
      'Design',
      'Coding',
      'Testing',
      'Maintenance',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
      'Firebase',
      'Lambda',
    ],
    site: 'KSK',
  },
  {
    name: '福祉施設管理システムのリプレース',
    description: [
      '元々はWindowsのデスクトップアプリだったものをウェブアプリケーションへリプレース。',
      '施設利用者の利用状況管理やスタッフの勤怠管理、自治体への提出する資料のエクセル出力など。',
    ],
    start_at: '2017-06',
    end_at: '2018-03',
    role: [
      'Leader',
      'Programmer',
    ],
    phase: [
      'Design',
      'Coding',
      'Testing',
      'Maintenance',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'CakePHP2',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
    ],
    site: 'Miyabi',
  },
  {
    name: 'マッチングアプリのアルファ版作成',
    description: [
      '位置情報を利用したマッチングアプリのアルファ版の作成。',
      '実際にはネイティブアプリで開発する予定だが、動作や投資家向けに実際に動作するウェブシステムを作成した。',
      'サーバサイドはPHPを、フロントエンドはJavaScriptで作成。',
    ],
    start_at: '2017-11',
    end_at: '2018-03',
    role: [
      'Leader',
      'Programmer',
    ],
    phase: [
      'Design',
      'Coding',
      'Testing',
    ],
    envelopment: [
      'Linux',
      'Apache',
      'MySQL',
      'PHP',
      'CakePHP2',
      'Html',
      'CSS',
      'JavaScript',
      'jQuery',
    ],
    site: 'NearMe',
  },
  {
    name: 'マッチングアプリ開発',
    description: [
      'アルファ版を作成したマッチングアプリのネイティブとしての開発。',
      '決済周りなどをメインで担当。',
    ],
    start_at: '2018-03',
    role: [
      'Programmer',
    ],
    phase: [
      'Design',
      'Coding',
      'Testing',
      'Maintenance',
    ],
    envelopment: [
      'ReactNative',
      'NodeJS',
      'MySQL',
      'JavaScript',
    ],
    site: 'NearMe',
  },
  {
    name: 'ECアプリのリプレース',
    description: [
      'もともと利用していたアプリCMSをやめることになり、それと同等のものを新規作成。',
      'アプリ及び、コンテンツ配信のためのCMS開発。',
      'サーバサイドは全てAWSを採用。',
    ],
    start_at: '2018-03',
    role: [
      'Leader',
      'Programmer',
    ],
    phase: [
      'Design',
      'Coding',
      'Testing',
      'Maintenance',
    ],
    envelopment: [
      'ReactNative',
      'NodeJS',
      'JavaScript',
      'jQuery',
      'DynamoDB',
      'S3',
      'Lambda',
      'CloudFront',
      'AWS API',
      'AWS WAF',
      'AWS SNS',
      'Java',
      'Objective-C',
    ],
    site: 'Yoshikei',
  },
]
