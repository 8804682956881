import React, { Component } from 'react'
import { Typography } from '@material-ui/core'

import { jobList, IJob } from '../../../variables/jobs'
import MultiLine from '../../widgets/MultiLine'

import styles from './style.module.css'

class JobListScreen extends Component {

  renderJob = () => {
    const items = []
    for ( const job of jobList ) {
      items.push(
        <section key={'job_' + job.name}>
          <Typography variant="h3">{job.name}</Typography>
          <MultiLine component="p" paragraph={true}>
            {job.description}
          </MultiLine>
        </section>,
      )
    }
    return items
  }

  render() {
    return (
      <section>
        <Typography variant="h2">Job</Typography>
        <Typography component="p" paragraph={true}>
          自分が就職していたり、エンジニアリングサービスとして常駐していた企業です。
        </Typography>
          {this.renderJob()}
      </section>
    )
  }
}

export default JobListScreen
