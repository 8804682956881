import React, { Component } from 'react'
import { Paper, Typography } from '@material-ui/core'

import ScrollToTopOnMount from '../../../utils/scrollToTopOnMount'

import SNS from './sns'

import styles from './style.module.css'

class ContactScreen extends Component {
  render() {
    return [
      <ScrollToTopOnMount key="scrollToTop" />,
      <Paper key="article" component="article">
        <Typography variant="h1">Contact</Typography>
        <Typography variant="body2" paragraph={true}>
          お問い合わせや羽賀子門へのメッセージはこちら。
        </Typography>
        <SNS />
      </Paper>,
    ]
  }
}

export default ContactScreen
