import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Helmet } from 'react-helmet'

import MenuStore from '../stores/menuStore'

import Home from '../stories/pages/Home'

interface Props {
  history?: any
  location?: any
  match?: any
  menuStore?: MenuStore
}

@inject( 'menuStore' )
@observer
class HomeContainer extends Component<Props> {
  constructor( props: Props ) {
    super( props )
  }

  componentDidMount() {
    const { menuStore, location } = this.props
    menuStore!.select( location.pathname )
  }

  render() {
    const baseUrl = process.env.REACT_APP_BASE_URL
    return (
      <React.Fragment>
        <Helmet
          title="Shimoning"
          meta={[
            {
              name: 'description',
              content: 'ウェブシステム・アプリケーションの開発はフルスタックエンジニア羽賀子門にお任せください。',
            },
            {
              name: 'keywords',
              content: '受託,システム開発,ウェブシステム,アプリ,ネイティブアプリ,クラウド,システムエンジニア,エンジニア,プログラマ,インフラ,SE,IT,WEB,engineer,programmer',
            },
            {
              property: 'og:title',
              content: 'Shimoning',
            },
            {
              property: 'og:type',
              content: 'website',
            },
            {
              property: 'og:description',
              content: 'ウェブシステム・アプリケーションの開発はフルスタックエンジニア羽賀子門にお任せください。',
            },
            {
              property: 'og:url',
              content: baseUrl,
            },
            {
              property: 'og:image',
              content: baseUrl + '/images/ogp/shimoning.png',
            },
          ]}
        />
        <Home />
      </React.Fragment>
    )
  }
}

export default HomeContainer
