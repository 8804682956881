import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Drawer, MenuList, MenuItem, Typography, Button } from '@material-ui/core'

import pages from '../../variables/pages'
import MenuStore from '../../stores/menuStore'

import styles from './style.module.css'

interface Props {
  menuStore?: MenuStore
}

@inject( 'menuStore' )
@observer
class SiteMenu extends Component<Props> {
  constructor( props: Props ) {
    super( props )
  }

  renderIcon = ( icon: any ) => {
    return React.createElement( icon, { className: styles.icon } )
  }

  renderMenu = () => {
    const menuItems = []
    for ( const page of pages ) {
      menuItems.push(
        <MenuItem
          selected={this.props.menuStore!.selected === page.path}
          key={'menu_' + page.name}
        >
          <Link
            to={page.path}
            className={styles.menu}
          >
            <Button>
              {this.renderIcon( page.icon )}
              <Typography component="span">{page.name}</Typography>
            </Button>
          </Link>
        </MenuItem>,
      )
    }

    return <MenuList>{menuItems}</MenuList>
  }

  render() {
    return (
      <Drawer
        anchor="left"
        variant="temporary"
        open={this.props.menuStore!.isOpen}
        onClick={this.props.menuStore!.close}
      >
        <Typography variant="headline" component="p">
          サイトメニュー
        </Typography>
        {this.renderMenu()}
      </Drawer>
    )
  }
}

export default SiteMenu
