import React, { Component } from 'react'
import { Paper, Typography } from '@material-ui/core'

import ScrollToTopOnMount from '../../../utils/scrollToTopOnMount'

import styles from './style.module.css'

import SkillList from './skillList'

class SkillsScreen extends Component {
  render() {
    return [
      <ScrollToTopOnMount key="scrollToTop" />,
      <Paper key="article" component="article">
        <Typography variant="h1">Skills</Typography>
        <Typography variant="body2" paragraph={true}>
          羽賀子門が持っている技術をご紹介します。<br />
          <br />
          いわゆる LAMP 環境での開発をメインに携わってきました。<br />
          近年は Node.js や React など JavaScript での開発も多くなってきました。
        </Typography>
        <SkillList />
      </Paper>,
    ]
  }
}

export default SkillsScreen
