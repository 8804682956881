import React, { Component } from 'react'
import { Typography } from '@material-ui/core'

import styles from './style.module.css'

class AbilityScreen extends Component {

  render() {
    return (
      <section>
        <Typography variant="h2">スキル</Typography>
        <Typography component="p" paragraph={true}>
          サーバサイドの開発がメインですが、<br/>
          フロントエンドやネイティブアプリの開発、DBの設計、サーバのセットアップも得意としております。<br/>
          AWSなどのクラウドを利用したサービスの開発も手がけております。<br/>
          <br/>
          ウェブシステムやアプリの構築を一人で広い範囲をカバーできる自信がございます。<br/>
        </Typography>
      </section>
    )
  }
}

export default AbilityScreen
