import FontAwesome from 'react-fontawesome'

import imageForQiita from '../assets/images/qiita-favicon.png'

import imageForGithub from '../assets/images/site/github-hexo.png'
import imageForYuzu from '../assets/images/site/yuzu-hexo.png'
import imageForWordpress from '../assets/images/site/wordpress.png'

export interface ICard {
  name: string
  description: string[]
  icon?: string | ILinkIcon
  image?: string | null
  link: ILink
  message: boolean
}

export interface ILink {
  text: string
  url: string
  target: TARGET
}

export type TARGET = '_blank' | '_parent'

export interface ILinkIcon {
  component: any
  name?: string
}

export const snsList: ICard[] = [
  {
    name: 'Twitter',
    description: [
      '',
    ],
    icon: {
      component: FontAwesome,
      name: 'twitter',
    },
    link: {
      text: '@shimon_haga',
      url: 'https://twitter.com/shimon_haga',
      target: '_blank',
    },
    message: true,
  },
  {
    name: 'Instagram',
    description: [
      '',
    ],
    icon: {
      component: FontAwesome,
      name: 'instagram',
    },
    link: {
      text: 'shimon_haga',
      url: 'https://www.instagram.com/shimon_haga',
      target: '_blank',
    },
    message: true,
  },
  {
    name: 'Facebook',
    description: [
      '',
    ],
    icon: {
      component: FontAwesome,
      name: 'facebook',
    },
    link: {
      text: 'haga.shimon',
      url: 'https://www.facebook.com/haga.shimon',
      target: '_blank',
    },
    message: true,
  },
  {
    name: 'Qiita',
    description: [
      '',
    ],
    image: imageForQiita,
    link: {
      text: 'shimon_haga',
      url: 'https://qiita.com/shimon_haga',
      target: '_blank',
    },
    message: false,
  },
  {
    name: 'Github',
    description: [
      '',
    ],
    icon: {
      component: FontAwesome,
      name: 'github',
    },
    link: {
      text: 'shimonhaga',
      url: 'https://github.com/shimonhaga',
      target: '_blank',
    },
    message: false,
  },
]

export const siteList: ICard[] = [
  {
    name: 'wordpress',
    description: [
      'Kusanagi という WordPress テンプレートを利用して ConoHa の VPS 上に構築。',
      'SSL 化も自動でしてくれる素晴らしいテンプレート。',
      '作っただけで満足。',
    ],
    image: imageForWordpress,
    link: {
      text: 'しもんの忘備録',
      url: 'https://shimon.xyz',
      target: '_blank',
    },
    message: false,
  },
  {
    name: 'github',
    description: [
      'Hexo を利用した静的ページを github ページとして公開。',
    ],
    image: imageForGithub,
    link: {
      text: 'Shimon on Github',
      url: 'https://shimonhaga.github.io/',
      target: '_blank',
    },
    message: false,
  },
  {
    name: 'yuzu',
    description: [
      '自宅で飼っているペットの柴犬のサイト。',
      'Hexo を利用した静的ページを s3 上に公開。',
      'CloudFront を利用し SSL の無料自動更新にも対応。',
    ],
    image: imageForYuzu,
    link: {
      text: '黒柴の柚子',
      url: 'https://yuzu.shibainu.love',
      target: '_blank',
    },
    message: false,
  },
]
