export interface IJob {
  name: string
  description: string[]
  start_at: string
  end_at?: string
  role: ROLE[]
  position: POSITION[]
}

export type ROLE = 'Programmer' | 'Director' | 'Leader' | 'Manager'
export type POSITION = 'SES' | 'PERMANENT' | 'TEMPORARY' | 'CONTRACT' | 'PART_TIME'

export const jobList: IJob[] = [
  // {
  //   name: '株式会社ブリンク',
  //   description: [
  //     '現在働いている会社です。',
  //     'ウェブシステムを中心に開発や保守の受託を行ってます。',
  //     '副業が許されているので、色々チャレンジしてみようと思います。',
  //   ],
  //   start_at: '2019-05',
  //   role: [
  //     'Programmer',
  //     'Director',
  //     'Leader',
  //     'Manager',
  //   ],
  //   position: [
  //     'PERMANENT',
  //   ],
  // },
  {
    name: '株式会社GOSPA',
    description: [
      '長らくお世話になった会社です。',
      'ウェブシステムを中心に開発や保守の受託を行ってきました。',
      'ネイティブアプリの開発や保守もやりました。',
      'インフラの構築や保守周りも覚えさせていただきました。',
    ],
    start_at: '2014-09',
    end_at: '2019-04',
    role: [
      'Programmer',
      'Director',
      'Leader',
      'Manager',
    ],
    position: [
      'PERMANENT',
    ],
  },
  {
    name: '株式会社NearMe',
    description: [
      '新規事業のためのアプリ開発および、バックエンド開発。',
      'アプリはReactNativeを採用し、バックエンドではGraphQLを用いた設計と開発を行いました。',
      '主に決済周りの設計と開発を担当してます。',
    ],
    start_at: '2018-03',
    role: [
      'Programmer',
    ],
    position: [
      'SES',
    ],
  },
  {
    name: '株式会社ボルテージ',
    description: [
      '新作ゲームの実装と一部設計。',
      'リリース直後の運営サポートとブラッシュアップ。',
      'イベントの作成と運営。',
    ],
    start_at: '2015-04',
    end_at: '2015-10',
    role: [
      'Programmer',
    ],
    position: [
      'PERMANENT',
    ],
  },
  {
    name: '株式会社アベリオシステムズ',
    description: [
      '某カーナビシステムのバックエンド開発。',
      '某空港内で利用する業務システムのウェブリプレース(インフラからフロントエンドまで)。',
      '新人のティーチング。',
    ],
    start_at: '2014-03',
    end_at: '2015-04',
    role: [
      'Programmer',
      'Leader',
    ],
    position: [
      'PERMANENT',
    ],
  },
  {
    name: '株式会社エイプリル・データ・デザインズ',
    description: [
      'LAMP + JavaScript で作成されたブラウザゲームの保守及び開発。',
      '上記ゲームのネイティブアプリ化。',
      '某SNSサイトの開発',
      '問診票システムの開発',
    ],
    start_at: '2013-02',
    end_at: '2014-02',
    role: [
      'Programmer',
    ],
    position: [
      'SES',
    ],
  },
  {
    name: 'アルファソリューションズ株式会社',
    description: [
      'IT業界に足を突っ込むきっかけになった会社です。',
      'この時点では趣味でウェブサイトを作る程度の知識と技術力しかなかったです。',
      '社内では某美容系の総合管理システムをLAMPで作っていて、CakePHP2を初めて経験しました。',
    ],
    start_at: '2012-12',
    end_at: '2014-08',
    role: [
      'Programmer',
    ],
    position: [
      'PERMANENT',
    ],
  },
]
