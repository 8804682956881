import React, { Component } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'

import theme from './variables/theme'

import Header from './components/Header'
import Content from './components/Content'
import Footer from './components/Footer'

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Header />
        <Content />
        <Footer />
      </MuiThemeProvider>
    )
  }
}

export default App
