import React, { Component } from 'react'
import { Switch } from 'react-router'
import { Route } from 'react-router-dom'

import ScrollToTop from '../ScrollToTop'

import styles from './style.module.css'

import pages from '../../variables/pages'

import NotFound from '../../containers/NotFoundContainer'

interface Props {}

class Content extends Component<Props> {
  constructor( props: Props ) {
    super( props )
  }

  renderRoute = () => {
    const routes = []
    for ( const page of pages ) {
      routes.push(
        <Route
          path={page.path}
          component={page.component}
          expact={page.expact}
          exact={true}
          key={'route_' + page.name}
        />,
      )
      if ( page.sub_pages ) {
        for ( const subPage of page.sub_pages ) {
          routes.push(
            <Route
              path={subPage.path}
              component={subPage.component}
              expact={subPage.expact}
              exact={true}
              key={'route_' + subPage.name}
            />,
          )
        }
      }
    }

    return routes
  }

  render() {
    return (
      <div id="content" className={styles.content}>
        <Switch>
          {this.renderRoute()}
          <Route component={NotFound} />
        </Switch>
        <ScrollToTop />
      </div>
    )
  }
}

export default Content
