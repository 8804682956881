import React, { Component } from 'react'
import { Typography } from '@material-ui/core'

import { skillList, ISkill } from '../../../variables/skills'
import MultiLine from '../../widgets/MultiLine'

import styles from './style.module.css'

class SkillListScreen extends Component {

  renderSkillGroup = () => {
    const groups = []
    for ( const skillGroup of skillList ) {
      const children = this.renderSkill( skillGroup.children )
      groups.push(
        <section className={styles.cartegory} key={'skillgroup_' + skillGroup.name}>
          <Typography variant="h2">{skillGroup.name}</Typography>
          <MultiLine component="p" paragraph={true}>
            {skillGroup.description}
          </MultiLine>
          {children}
        </section>,
      )
    }
    return groups
  }

  renderSkill = ( skills: ISkill[] ) => {
    const items = []
    for ( const skill of skills ) {
      items.push(
        <section key={'skill_' + skill.name}>
          <Typography variant="h3">{skill.name}</Typography>
          <MultiLine component="p" paragraph={true}>
            {skill.description}
          </MultiLine>
        </section>,
      )
    }
    return items
  }

  render() {
    return this.renderSkillGroup()
  }
}

export default SkillListScreen
