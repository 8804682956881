import { action, observable } from 'mobx'

class MenuStore {
  @observable isOpen: boolean = false
  @observable selected: string = ''

  @action.bound click() {
    this.isOpen = !this.isOpen
  }

  @action.bound open() {
    this.isOpen = true
  }

  @action.bound close() {
    this.isOpen = false
  }

  @action.bound select( path: string = '', event?: any ) {
    this.selected = path
  }
}

export const menuStore = new MenuStore()
export default MenuStore
