import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import MenuStore from '../../stores/menuStore'

import styles from './style.module.css'
import logo from '../../assets/images/logo_s_shimoning_w.png'

import SiteMenu from '../SiteMenu'

interface Props {
  menuStore?: MenuStore
}

@inject( 'menuStore' )
@observer
class Header extends Component<Props> {
  constructor( props: Props ) {
    super( props )
  }

  render() {
    return (
      <AppBar>
        <Toolbar
          variant="dense"
          disableGutters={true}
          className={styles.toolbar}
        >
          <IconButton
            color="inherit"
            aria-label="Menu"
            onClick={this.props.menuStore!.click}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <Typography
              color="secondary"
              component="span"
              className={styles.text}
            >
              <img src={logo} alt="Shimoning" title="Shimoning" />
            </Typography>
          </Link>
        </Toolbar>
        <SiteMenu />
      </AppBar>
    )
  }
}

export default Header
