import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'

import pages from '../../variables/pages'
import MenuStore from '../../stores/menuStore'

import styles from './style.module.css'

interface Props {
  menuStore?: MenuStore
}

@inject( 'menuStore' )
@observer
class Footer extends Component<Props> {
  constructor( props: Props ) {
    super( props )
  }

  renderMenu = () => {
    const menu = []
    for ( const page of pages ) {
      if ( !page.path || page.path === '/' ) {
        continue
      }

      const selected = this.props.menuStore!.selected === page.path

      menu.push(
        <Grid
          item
          key={'footer_' + page.name}
          className={selected ? styles.itemSelected : styles.item}
        >
          <Link to={page.path}>
            <Typography>{page.name}</Typography>
          </Link>
        </Grid>,
      )
    }
    return menu
  }

  render() {
    return (
      <footer className={styles.footer}>
        <Grid container justify="center" className={styles.container}>
          <Grid item className={styles.itemTitle}>
            <Link to="/" className={styles.linkHome}>
              <Typography variant="subtitle2" component="span" className={styles.footerTitle}>
                Shimoning
              </Typography>
            </Link>
          </Grid>
          {this.renderMenu()}
        </Grid>
      </footer>
    )
  }
}

export default Footer
