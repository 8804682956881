import React, { Component } from 'react'
import { IconButton } from '@material-ui/core'
import { ArrowUpward } from '@material-ui/icons'

import styles from './style.module.css'

interface Props {
  interval?: number
  top?: number
  step?: number
}

interface State {
  timer?: any
}

class ScrollToTop extends Component<Props, State> {
  constructor( props: Props ) {
    super( props )

    this.state = {
      timer: null,
    }
  }

  step = () => {
    const top = this.props.top ? this.props.top : 0
    const step = this.props.step ? this.props.step : 100
    if ( window.pageYOffset <= top ) {
      clearInterval( this.state.timer )
    }
    const deltaTop = window.pageYOffset - step
    window.scrollTo( 0, deltaTop )
  }

  onClick = () => {
    const interval = this.props.interval ? this.props.interval : 15
    const timer = setInterval( () => {
      this.step()
    }, interval )
    this.setState( { timer } )
  }

  render() {
    return (
      <IconButton
        className={styles.button}
        color="primary"
        onClick={this.onClick}
      >
        <ArrowUpward />
      </IconButton>
    )
  }
}

export default ScrollToTop
