import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Typography, Grid, IconButton, Card, CardMedia, CardContent } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'

import pages from '../../../variables/pages'

import styles from './style.module.css'

class MenuScreen extends Component {

  renderGrid = () => {
    const grids = []
    for ( const page of pages ) {
      if ( !page.path || page.path === '/' ) {
        continue
      }
      grids.push(
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={'menu_' + page.name}
        >
          <Card className={styles.tile}>
            <Link to={page.path}>
              <CardMedia
                image={page.image_public}
                className={styles.image}
              />
              <CardContent className={styles.title}>
                <Typography variant="h3" className={styles.titleText}>
                  {page.name}
                </Typography>
                <Typography variant="body2" className={styles.titleText}>
                  {page.headline}
                </Typography>
              </CardContent>
            </Link>
          </Card>
        </Grid>,
      )
    }
    return grids
  }

  render() {
    return (
      <section>
        <Typography variant="h2">ポートフォリオ</Typography>
        <div className={styles.wrapper}>
          <Grid
            container
            spacing={8}
            className={styles.list}
          >
            {this.renderGrid()}
          </Grid>
        </div>
      </section>
    )
  }
}

export default MenuScreen
