import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import MenuStore from '../../stores/menuStore'

import Applications from '../../stories/pages/Applications'

interface Props {
  history?: any
  location?: any
  match?: any
  menuStore?: MenuStore
}

@inject( 'menuStore' )
@observer
class ApplicationsContainer extends Component<Props> {
  constructor( props: Props ) {
    super( props )
  }

  componentDidMount() {
    const { menuStore, location } = this.props
    menuStore!.select( location.pathname )
  }

  render() {
    return (
      <Applications />
    )
  }
}

export default ApplicationsContainer
