import React from 'react'
import {
  Home as HomeIcon,
  Person as ProfileIcon,
  Work as WorksIcon,
  Edit as SkillsIcon,
  Email as ContactIcon,
  Apps as ApplicationsIcon,
} from '@material-ui/icons'

import HomeComponent from '../containers/HomeContainer'
import ProfileComponent from '../containers/ProfileContainer'
import WorksComponent from '../containers/WorksContainer'
import SkillsComponent from '../containers/SkillsContainer'
import ContactComponent from '../containers/ContactContainer'
import ApplicationsComponent from '../containers/Applications/ApplicationsContainer'
import MapsGeojsonComponent from '../containers/Applications/MapsGeojsonContainer'

import imageForProfile from '../assets/images/home/my_icon.jpg'
import imageForWorks from '../assets/images/home/snowfield.jpg'
import imageForSkills from '../assets/images/home/my_mac.jpg'
import imageForApplications from '../assets/images/home/oriduru.jpg'
import imageForContact from '../assets/images/home/coffee.jpg'

export interface IPage {
  path: string
  name: string
  component: React.ComponentType
  expact: boolean
  headline: string
  description: string[]
  image?: any
  image_public?: string
  icon?: any
  sub_pages?: IPage[]
}

const pages: IPage[] = [
  {
    path: '/',
    name: 'Home',
    component: HomeComponent,
    expact: true,
    headline: '',
    description: [],
    image: null,
    image_public: '',
    icon: HomeIcon,
  },
  {
    path: '/skills',
    name: 'Skills',
    component: SkillsComponent,
    expact: false,
    headline: '身につけてきた技術について',
    description: [
      '羽賀子門が持っている技術をご紹介します。',
      '',
      'いわゆる LAMP 環境での開発を多く携わってきました。',
      '近年は Node.js や React など JavaScript での開発も増えてきました。',
    ],
    image: imageForSkills,
    image_public: '/images/contents/my_mac.jpg',
    icon: SkillsIcon,
  },
  {
    path: '/works',
    name: 'Works',
    component: WorksComponent,
    expact: false,
    headline: '今まで携わってきたお仕事について',
    description: [
      '羽賀子門が今まで働いてきた会社や、プロジェクトをご紹介します。',
    ],
    image: imageForWorks,
    image_public: '/images/contents/snowfield.jpg',
    icon: WorksIcon,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileComponent,
    expact: false,
    headline: '自己紹介させていただきます',
    description: [
      'はじめまして。',
      '羽賀子門について自己紹介させていただきます。',
    ],
    image: imageForProfile,
    image_public: '/images/contents/my_icon.jpg',
    icon: ProfileIcon,
  },
  {
    path: '/apps',
    name: 'Applications',
    component: ApplicationsComponent,
    expact: false,
    headline: '作ってるアプリケーション',
    description: [
      '羽賀子門が趣味で作ってるアプリケーションやツールなど。',
    ],
    image: imageForApplications,
    image_public: '/images/contents/oriduru.jpg',
    icon: ApplicationsIcon,
    sub_pages: [
      {
        path: '/apps/maps/geojson',
        name: 'AppsMapsGeojson',
        component: MapsGeojsonComponent,
        expact: false,
        headline: 'GoogleMapで行政区画を描く',
        description: [
          '国土地理院の情報を利用し、GoogleMap上に行政区画を描く。',
        ],
      },
    ],
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactComponent,
    expact: false,
    headline: 'お問い合わせはこちら',
    description: [
      'このサイトもしくは羽賀子門に関するお問い合わせはこちらから。',
    ],
    image: imageForContact,
    image_public: '/images/contents/coffee.jpg',
    icon: ContactIcon,
  },
]
export default pages
