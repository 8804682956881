import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Paper, MenuList, MenuItem, Typography, Button } from '@material-ui/core'

import pages, { IPage } from '../../../variables/pages'
import MenuStore from '../../../stores/menuStore'

import ScrollToTopOnMount from '../../../utils/scrollToTopOnMount'

import styles from './style.module.css'

interface Props {
  menuStore?: MenuStore
}

@inject( 'menuStore' )
@observer
class ApplicationsScreen extends Component<Props> {
  links: IPage[] = []
  constructor( props: Props ) {
    super( props )
  }

  componentWillMount() {
    const Application = pages.filter((page) => page.name === 'Applications')
    this.links = Application && Application[0].sub_pages ? Application[0].sub_pages : []
  }

  renderLink = () => {
    const linkItems = []
    for ( const link of this.links ) {
      linkItems.push(
        <MenuItem
          selected={this.props.menuStore!.selected === link.path}
          key={'menu_' + link.name}
        >
          <Link
            to={link.path}
            className={styles.menu}
          >
              <Typography component="span">{link.name}</Typography>
          </Link>
        </MenuItem>,
      )
    }

    return <MenuList>{linkItems}</MenuList>
  }

  render() {
    return [
      <ScrollToTopOnMount key="scrollToTop" />,
      <Paper key="article" component="article">
        <Typography variant="h1">Application</Typography>
        <Typography variant="body2" paragraph={true}>
          羽賀子門が趣味で開発したツールなど。
        </Typography>
        {this.renderLink()}
      </Paper>,
    ]
  }
}

export default ApplicationsScreen
