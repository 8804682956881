import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import MenuStore from '../stores/menuStore'

import Profile from '../stories/pages/Profile'

interface Props {
  history?: any
  location?: any
  match?: any
  menuStore?: MenuStore
}

@inject( 'menuStore' )
@observer
class ProfileContainer extends Component<Props> {
  constructor( props: Props ) {
    super( props )
  }

  componentDidMount() {
    const { menuStore, location } = this.props
    menuStore!.select( location.pathname )
  }

  render() {
    return (
      <Profile />
    )
  }
}

export default ProfileContainer
